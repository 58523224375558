import theme from 'gatsby-theme-listing/src/gatsby-plugin-theme-ui'

export default {
    ...theme,
    fonts: {
        body: "'Space Mono', monospace",
        heading: "'BioRhyme', serif",
        monospace: "'Space Mono', monospace",
      },
    fontWeights: {
        body: 300,
        heading: 700,
        bold: 700,
    },  
    colors: {
        text: '#272931',
        background: '#fff',
        grey: '#6e7180',
        primary: '#40bd89',
        secondary: '#f8bb60',
        muted: '#f0eeeb',
    },
}